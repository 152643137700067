import React from "react";
import './FileDownloadButtonAndroid.scss'

const FileDownloadButtonAndroid = ({ fileUrl, fileName ,name}) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="buttonAndroid">
      <button onClick={handleDownload} className="androidTextlogo">
        {name}
      </button>
    </div>
  );
};

export default FileDownloadButtonAndroid;
