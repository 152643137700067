import React, { useEffect, useState } from "react";
// import "./Main.scss";

import Header from "../componets/header/Header";
import FirstCompo from "../componets/First/FirstCompo";
import SecondComponent from "../componets/second/SecondComponent";
import ThirdCompo from "../componets/third/ThirdCompo";
import FourthCompo from "../componets/fourth/FourthCompo";
import FifthCompo from "../componets/fifth/FifthCompo";
import DownloadBox from "../componets/DownloadBox/DownloadBox";
import FooterEl from "../componets/Footer/FooterEl";
import DownloadAndroid from "../componets/AndroidDownload/DownloadAndroid";

const Main = () => {
  return (
    <>
      <Header />
      <FirstCompo />
      <DownloadAndroid />
      <SecondComponent />
      <ThirdCompo />
      <FourthCompo />
      <FifthCompo />
      <DownloadBox />
      <FooterEl />
    </>
  );
};

export default Main;
