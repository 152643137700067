import React from "react";
import androidIc from "../../Images/android-white.png";
import appstoreWhite from "../../Images/apple.png";
import QrCode from "../../Images/qr-code.png";
import "./DownloadAndroid.scss";
import FileDownloadButton from "./FileDownloadButtonAndroid";

const DownloadAndroid = () => {
  return (
    <div className="androidWrapper">
      {/* android box componenet */}

      <div className="androidBox">
        <div className="androidIconContainer">
          <img src={androidIc} className="androidIcon" alt="Logo" />

          <FileDownloadButton
            fileUrl="https://cdn.dribbble.com/userupload/6699321/file/original-19c066994c6733b8fa60d512bffe7c2c.jpg?resize=1024x768"
            // fileUrl="https://firebasestorage.googleapis.com/v0/b/chatandmoney-3033.appspot.com/o/uploads%2Fchatandmoney.apk?alt=media&token=7d896981-443d-436f-aee4-7c95370bec07"
            fileName="Chatandmoney.apk"
            name="Android app"
          />
        </div> 
      </div>
      {/* ios box componenet */}
      <div className="androidBox">
        <div className="androidIconContainer">
          <img src={appstoreWhite} className="androidIcon" alt="Logo" />

          <FileDownloadButton
            fileUrl="https://cdn.dribbble.com/userupload/6699321/file/original-19c066994c6733b8fa60d512bffe7c2c.jpg?resize=1024x768"
            // fileUrl="https://firebasestorage.googleapis.com/v0/b/chatandmoney-3033.appspot.com/o/uploads%2Fchatandmoney.apk?alt=media&token=7d896981-443d-436f-aee4-7c95370bec07"
            fileName="Chatandmoney.apk"
            name="iOS app"
          />
        </div>
      </div>
      {/* Web box componenet */}

      <img src={QrCode} className="QrCode" alt="Logo" />
    </div>
  );
};

export default DownloadAndroid;
