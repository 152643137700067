import React from "react";
import "./Footer.scss";
import facebook1 from "../../Images/facebook1.webp";
import instagram from "../../Images/instagram.webp";
import linkedin from "../../Images/linkedin1.webp";
import telegram from "../../Images/telegramlogo.webp";
import twiter from "../../Images/twitter1.webp";
import youtube from "../../Images/youtube1.webp";
import whatsapp from "../../Images/whatsapp.png";
const FooterEl = () => {
  return (
    <div className="footerContainer">
      <div className="links">
        <text className="footerAppname">Chat&money</text>
        <text className="footerAppname-com">chatandmoney.com</text>
        <div className="linkIcons">
          <a
            href="https://www.facebook.com/profile.php?id=61561833271265"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={facebook1}
              style={{ borderRadius: "10px" }}
              alt="Logo"
              width={30}
              height={30}
            />
          </a>

          <a
            href="https://whatsapp.com/channel/0029VacXbB06hENyBuQik21v"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={whatsapp}
              style={{
                borderRadius: "90px",
                backgroundColor: "#fff",
                padding: "4px",
              }}
              alt="Logo"
              width={25}
              height={25}
            />
          </a>
          {/* <img
            src={twiter}
            style={{ borderRadius: "10px" }}
            alt="Logo"
            width={30}
            height={30}
          /> */}
          {/* <img
            src={linkedin}
            style={{ borderRadius: "10px" }}
            alt="Logo"
            width={30}
            height={30}
          /> */}

          <a
            href="    https://www.youtube.com/@ChatandMoney"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={youtube}
              style={{ borderRadius: "10px" }}
              alt="Logo"
              width={30}
              height={30}
            />
          </a>
          <a
            href="https://www.instagram.com/chatandmoney?igsh=OXVmMmVyNjZremI0&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagram}
              style={{ borderRadius: "10px" }}
              alt="Logo"
              width={30}
              height={30}
            />
          </a>
          {/* <img
            src={telegram}
            style={{ borderRadius: "10px" }}
            alt="Logo"
            width={30}
            height={30}
          /> */}
        </div>
        <text style={{ color: "#fff", marginTop: "20px" }}></text>
        <text style={{ color: "#fff", marginTop: "20px" }}>
          This game may be habit-forming or financially risky. Play responsibly.
        </text>
      </div>
      {/* <div className="links">
        <text
          style={{
            marginTop: "20px",
            color: "#ffffff",
            fontWeight: "lighter",
            fontsize: "1.8em",
            fontWeight: "700",
            fontFamily: "Quicksand",
          }}
        >
          This game may be habit-forming or financially risky. Play responsibly.
        </text>
      </div> */}
      <div className="footerBase"></div>
    </div>
  );
};

export default FooterEl;
